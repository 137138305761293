import React, { PropsWithChildren, PureComponent } from 'react';

import styled, { css } from 'styled-components';

import { Align } from '@shared/enums/align';
import { Colors } from '@shared/interfaces/themes';
import { breakpoint } from 'theme';
import { getStyle } from '@shared/markup/functions';

type BoxProps<C extends React.ElementType>= {
  productIcon?: string;
  className?: string;
  backgroundColor?: keyof Colors;
  alignItems?: Align;
  height?: string | number;
  width?: string | number;
  maxWidth?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  smMt?: number;
  smMr?: number;
  smMb?: number;
  smMl?: number;
  mMt?: number;
  mMr?: number;
  mMb?: number;
  mMl?: number;
  mdMt?: number;
  mdMr?: number;
  mdMb?: number;
  mdMl?: number;
  lgMt?: number;
  lgMr?: number;
  lgMb?: number;
  lgMl?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
  smPt?: number;
  smPr?: number;
  smPb?: number;
  smPl?: number;
  mPt?: number;
  mPr?: number;
  mPb?: number;
  mPl?: number;
  mdPt?: number;
  mdPr?: number;
  mdPb?: number;
  mdPl?: number;
  lgPt?: number;
  lgPr?: number;
  lgPb?: number;
  lgPl?: number;
  align?: Align;
  smAlign?: Align;
  mAlign?: Align;
  mdAlign?: Align;
  lgAlign?: Align;
  id?: string;
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onBlur?: () => void;
  isActive?: boolean;
  selected?: boolean;
  ariaLabel?: string;
  ariaCurrentStep?: boolean;
  role?: string;
  as?: C;
};

class Box<C extends React.ElementType = 'div'> 
  extends PureComponent<PropsWithChildren<BoxProps<C>>> 
{
  render() {
    const { 
      children, 
      className, 
      id, 
      onClick, 
      onBlur, 
      ariaLabel, 
      ariaCurrentStep, 
      role,
      as: Element = 'div'
    } = this.props;

    return (
      <Element
        aria-label={ariaLabel}
        role={role}
        aria-current={ariaCurrentStep ? 'step' : undefined}
        className={className}
        id={id}
        onClick={onClick}
        onBlur={onBlur}
      >
        {children}
      </Element>
    );
  }
}

const styledBox = styled(Box)`
  position: relative;
  ${props =>
    props.alignItems
      ? css`
          height: 100%;
          display: flex;
          align-items: ${Align[props.alignItems]};
        `
      : css`
          display: block;
        `}
  ${props => getStyle(props.align, 'text-align')}

  ${props =>
    props.backgroundColor &&
    css`
      background-color: ${props.theme.colors[props.backgroundColor]};
    `}

  ${props =>
    props.height &&
    css`
      height: ${typeof props.height === 'string'
        ? props.height
        : `${props.height}em`};
    `}

  ${props =>
    props.width &&
    css`
      width: ${typeof props.width === 'string'
        ? props.width
        : `${props.width}em`};
    `}

  ${props =>
    props.maxWidth &&
    css<{ maxWidth?: string | number }>`
      max-width: ${props => props.maxWidth}em;
      margin-left: auto;
      margin-right: auto;
    `}

  ${props => getStyle(props.mt, 'margin-top', 'em')}
  ${props => getStyle(props.mr, 'margin-right', 'em')}
  ${props => getStyle(props.mb, 'margin-bottom', 'em')}
  ${props => getStyle(props.ml, 'margin-left', 'em')}
  
  ${props => getStyle(props.pt, 'padding-top', 'em')}
  ${props => getStyle(props.pr, 'padding-right', 'em')}
  ${props => getStyle(props.pb, 'padding-bottom', 'em')}
  ${props => getStyle(props.pl, 'padding-left', 'em')}

  ${breakpoint.down('ltm')`
    ${props => getStyle(props.smMt, 'margin-top', 'em')}
    ${props => getStyle(props.smMr, 'margin-right', 'em')}
    ${props => getStyle(props.smMb, 'margin-bottom', 'em')}
    ${props => getStyle(props.smMl, 'margin-left', 'em')}
    
    ${props => getStyle(props.smPt, 'padding-top', 'em')}
    ${props => getStyle(props.smPr, 'padding-right', 'em')}
    ${props => getStyle(props.smPb, 'padding-bottom', 'em')}
    ${props => getStyle(props.smPl, 'padding-left', 'em')}
    
    ${props => getStyle(props.smAlign, 'text-align')}
  `}

  ${breakpoint.only('m', 'ltLg')`
    ${props => getStyle(props.mMt, 'margin-top', 'em')}
    ${props => getStyle(props.mMr, 'margin-right', 'em')}
    ${props => getStyle(props.mMb, 'margin-bottom', 'em')}
    ${props => getStyle(props.mMl, 'margin-left', 'em')}
    
    ${props => getStyle(props.mPt, 'padding-top', 'em')}
    ${props => getStyle(props.mPr, 'padding-right', 'em')}
    ${props => getStyle(props.mPb, 'padding-bottom', 'em')}
    ${props => getStyle(props.mPl, 'padding-left', 'em')}
    
    ${props => getStyle(props.mAlign, 'text-align')}
  `}
  
  ${breakpoint.up('md')`
    ${props => getStyle(props.mdMt, 'margin-top', 'em')}
    ${props => getStyle(props.mdMr, 'margin-right', 'em')}
    ${props => getStyle(props.mdMb, 'margin-bottom', 'em')}
    ${props => getStyle(props.mdMl, 'margin-left', 'em')}

    ${props => getStyle(props.mdPt, 'padding-top', 'em')}
    ${props => getStyle(props.mdPr, 'padding-right', 'em')}
    ${props => getStyle(props.mdPb, 'padding-bottom', 'em')}
    ${props => getStyle(props.mdPl, 'padding-left', 'em')}
    
    ${props => getStyle(props.mdAlign, 'text-align')}
  `}

  ${breakpoint.up('lg')`
    ${props => getStyle(props.lgMt, 'margin-top', 'em')}
    ${props => getStyle(props.lgMr, 'margin-right', 'em')}
    ${props => getStyle(props.lgMb, 'margin-bottom', 'em')}
    ${props => getStyle(props.lgMl, 'margin-left', 'em')}

    ${props => getStyle(props.lgPt, 'padding-top', 'em')}
    ${props => getStyle(props.lgPr, 'padding-right', 'em')}
    ${props => getStyle(props.lgPb, 'padding-bottom', 'em')}
    ${props => getStyle(props.lgPl, 'padding-left', 'em')}
    
    ${props => getStyle(props.lgAlign, 'text-align')}
  `}
`;

export default styledBox;
